import { ImageTheme } from '@/components/Image/theme';
import { tw } from '@/utils/tw';
import BaseArticleTeaserDefaultTheme from 'base/components/ArticleTeaser/Default/theme';

const Image = tw.theme({
  extend: ImageTheme,
  base: `aspect-default`,
});

const ArticleTeaserDefault = tw.theme({
  extend: BaseArticleTeaserDefaultTheme,
  slots: {
    caption: `text-gray-600`,
    description: `hidden`,
  },
  variants: {
    variant: {
      horizontal: {
        caption: ``,
        headline: `text-headline-xs sm:grid-cols-4:text-headline-sm`,
      },
      vertical: {
        caption: `group-[.bg]/box:mx-4`,
        headline: `group-[.bg]/box:mx-4 group-[.bg]/box:mb-4 grid-cols-7:text-headline-sm sm:text-headline-sm sm:grid-cols-4:text-headline-md sm:grid-cols-7:text-headline-lg`,
      },
    },
  },
});

export default Object.assign(ArticleTeaserDefault, { Image });
